/**
 * Parallax Component
 * 
 * Adds a parallax scrolling effect to images.
 * 
 * Attributes:
 * - `data-parallax`: Container for the effect.
 * - `data-parallax-image`: Image to animate.
 * 
 * Constructor:
 * - new Parallax(containerSelector, throttleLimit)
 *   - containerSelector: Selector for `data-parallax` (string).
 *   - throttleLimit: Scroll throttle delay in ms (default: 50).
 * 
 * Usage:
 * HTML:
 * <div data-parallax>
 *   <img data-parallax-image src="image.jpg" alt="Parallax Image">
 * </div>
 * 
 * JS:
 * new Parallax('[data-parallax]', 50);
 */

export default class Parallax {
	constructor(container, throttleLimit = 100) {
		this.container = container;
		if (!this.container) {
			throw new Error('Parallax component: no container found with provided selector.')
		}
		this.image = this.container.querySelector('[data-parallax-image]');
		if (!this.image) {
			throw new Error('Parallax component: no image found with data-parallax-image attribute within container.')
		}
		this.throttleLimit = throttleLimit;

		if (this.image) {
			this.init();
		}
	}

	init() {
		
		this.handleScroll = this.throttle(this.handleScroll.bind(this), this.throttleLimit);
		window.addEventListener('scroll', this.handleScroll);
		this.moveImage(121);
	}

	// Throttle function to limit execution of the scroll handler
	throttle(func, limit) {
		let lastFunc;
		let lastRan;
		return function (...args) {
			const context = this;
			if (!lastRan) {
				func.apply(context, args);
				lastRan = Date.now();
			} else {
				clearTimeout(lastFunc);
				lastFunc = setTimeout(() => {
					if (Date.now() - lastRan >= limit) {
						func.apply(context, args);
						lastRan = Date.now();
					}
				}, limit - (Date.now() - lastRan));
			}
		};
	}

	// Main scroll handler for parallax effect
	handleScroll() {
		const viewportHeight = window.innerHeight;
		const topOfImage = this.image.getBoundingClientRect().top;
		const bottomOfImage = topOfImage + this.image.offsetHeight;

		if (this.isImageInViewport(topOfImage, bottomOfImage, viewportHeight)) {
			this.moveImage(topOfImage);
		}
	}

	// Check if the image is in the viewport
	isImageInViewport(topOfImage, bottomOfImage, viewportHeight) {
		return topOfImage < viewportHeight && bottomOfImage > 0;
	}

	// Update the image position for parallax effect
	moveImage(topOfImage) {
		const positionY = Math.min(100, Math.max(0, ((1 - topOfImage / window.innerHeight) / 3) * 100));
		this.image.style.objectPosition = `50% ${positionY}%`;
	}
}
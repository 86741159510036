
// import Swiper JS
import Swiper from 'swiper';
import { Navigation, Pagination, EffectCoverflow, Autoplay, EffectFade } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, EffectFade]);


const featuredSlider = () => {
    let wrapper = document.querySelector('.featuredSlider');
    let number = 3;

    let swiper = new Swiper('.featuredSlider', {
        loop: true,
        lazy: true,
        speed: 600,
        slidesPerView: 1,

        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            480: {
                slidesPerView: 1.5,
            },
            768: {
                slidesPerView: 1,
            },
            1024: {
                slidesPerView: 1.5,
            },
            1200: {
                slidesPerView: 2.5,
            }
        }
    });
}

const teamSlider = () => {
    let wrapper = document.querySelector('.teamSlider');
    let number = 3;

    let swiper = new Swiper('.teamSlider', {
        loop: true,
        lazy: true,
        speed: 600,
        slidesPerView: 1,

        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // autoplay: {
        //     // delay: 880000
        // },
        breakpoints: {
            1024: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            }
        }
    });
}

const homeBanner = () => {
    let wrapper = document.querySelector('.homeBanner');
    let number = 1;

    let autoHeight = false;
    // if small screen, set auto height
    if (window.innerWidth < 768) {
        autoHeight = true;
    }

    let swipered = new Swiper('.homeBanner', {
        effect: "fade",
        fade: {
            crossFade: false
        },
        loop: true,
        autoHeight: autoHeight,
        lazy: true,
        speed: 300,
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        autoplay: {
            delay: 10000
        }
    });
}

const timelineSlider = () => {
    let wrapper = document.querySelector('.timelineSlider');
    let number = 3;

    let swiper = new Swiper('.timelineSlider', {
        effect: "coverflow",
        loop: true,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 1.5,
        coverflowEffect: {
            rotate: 10,
            stretch: 0,
            depth: 20,
            modifier: 1,
            slideShadows: true,
        },
        lazy: true,
        speed: 600,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 1.8,
                spaceBetween: 40
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 50
            }
        }
        // autoplay: {
        //     delay: 8000
        // }
    });
}



export { featuredSlider, homeBanner, teamSlider, timelineSlider };

import { lazyLoad } from 'unlazy';
import Parallax from './components/parallax';
import Tabs from './components/tabs';
import Accordion from './components/accordion';
import Lightbox from './components/lightbox';
import modals from './components/modals';
import counter from './components/counter';


import { Navigation } from 'swiper/modules';

import { featuredSlider, homeBanner, teamSlider, timelineSlider } from './components/sliders';

// async function loadPageModule(moduleName) {
//     try {
//         const module = await import(`./pages/${moduleName}.js`);
//         console.log(`${moduleName} module loaded`);
//     } catch (err) {
//         console.error(`Failed to load ${moduleName} module`, err);
//     }
// }

(() => {
    const page = document.body.dataset.page;
    if (page) {
        // loadPageModule(page);
        console.log(`Loading ${page} page...`);
    }
    // lazyLoad('img[data-srcset]')
	document.querySelectorAll('[data-parallax]').forEach((container) => {
		new Parallax(container);
	});
    
	new Tabs('[data-tabs]');

    new Accordion('[data-accordion]');

    new Lightbox('[data-lightbox]');
})();

let homeNav = () => {
    // let homepage = document.querySelector('body').dataset.page-type;
    let homepage = document.querySelector('body').getAttribute('data-page-type') == 'home';

    if (homepage) {
        let nav = document.querySelector('header');
        if (nav) {
            let navHeight = nav.offsetHeight;
            nav.classList.add('homeNav');
        }
    }
}


// // banner style 3
const findContainerLeftOffset = () => {
    const firstContainer = document.querySelector('.container');
    if (firstContainer) {
        let offsetLeft = firstContainer.offsetLeft;

        document.documentElement.style.setProperty('--container-offset', `${offsetLeft}px`);

        if (window.innerWidth > 1199) {
            const containerLeftOffset = document.querySelectorAll('.containerLeftOffset');
            const viewportWidth = window.innerWidth; // 100vw equivalent

            containerLeftOffset.forEach((element) => {
                const newWidth = `${viewportWidth - offsetLeft - 30 }px`;

                // Apply the calculated width
                element.style.width = newWidth;
            });
        }
    }
};

const offCanvas = () => {
    const triggerButtons = document.querySelectorAll('[data-offcanvas-trigger]');
    const containers = document.querySelectorAll('[data-offcanvas-container]');

    containers.forEach(container => {
        container.classList.add('duration-300');

        const submenuItems = container.querySelectorAll('li.has-submenu');

        submenuItems.forEach(item => {
            // Add a click event to toggle submenu
            item.querySelector('div').addEventListener('click', () => {
                submenuItems.forEach(item2 => { 
                    // check if item2 and item are not the same
                    if (item !== item2) {
                        item2.classList.remove('show');
                    } else {
                        item2.classList.toggle('show');
                    }
                });
            });

            let childMenuItems = item.querySelectorAll('li.dropdown');

            childMenuItems.forEach(item => {
                item.querySelector('div').addEventListener('click', () => {
                    childMenuItems.forEach(item2 => { 
                        // check if item2 and item are not the same
                        if (item !== item2) {
                            item2.classList.remove('show');
                        } else {
                            item2.classList.toggle('show');
                        }
                    });
                });
            })
        });
    })

    triggerButtons.forEach(button => {
        button.addEventListener('click', () => {
            const target = button.getAttribute('data-offcanvas-trigger');
            const container = document.querySelector(`[data-offcanvas-container="${target}"]`);

            if (container) {
                container.classList.toggle('translate-x-full');
                container.classList.toggle('translate-x-0');

                //toggle scroll lock
                document.body.classList.toggle('overflow-hidden');
            }
        });
    });
}

const teamModal = () => {
    const teamOpen = document.querySelectorAll('.teamOpen');
    const teamBox = document.querySelectorAll('.teamBox');

    teamOpen.forEach(item => {
        item.addEventListener('click', (e) => {
            // e.preventDefault();
            let teamId = item.getAttribute('data-team-open');

            teamBox.forEach(box => {
                box.classList.add('hidden');
            });

            const thisBox = document.getElementById(teamId);

            thisBox.classList.remove('hidden');
        });
    });
    teamBox.forEach(box => {

        box.querySelector('[data-team-close]').addEventListener('click', () => {
            box.classList.add('hidden');
        });
    });

    // get url #
    const hash = window.location.hash;
    if (hash) {
        const teamBox = document.getElementById(hash.slice(1)+'-box');
        if (teamBox) {
            teamBox.classList.remove('hidden');
        }
    }
}

const tabs = () => {
    const tabOpen = document.querySelectorAll('.tabOpen');
    const tabBox = document.querySelectorAll('.tabBox');

    tabOpen.forEach(item => {
        item.addEventListener('click', (e) => {
            // e.preventDefault();
            let tabId = item.getAttribute('data-tab-open');

            tabOpen.forEach(item => {
                item.classList.remove('active');
            });

            item.classList.add('active');

            console.log("🚀 ~ item.addEventListener ~ tabId:", tabId)


            tabBox.forEach(box => {
                box.classList.add('hidden');
            });

            const thisBox = document.getElementById('tab-'+tabId);

            thisBox.classList.remove('hidden');
        });
    });
}

// on doc ready
window.addEventListener('DOMContentLoaded', () => {

    lazyLoad('img[data-lazy-load]')

    featuredSlider();

    homeNav();

    findContainerLeftOffset();

    homeBanner();

    timelineSlider();

    teamSlider();

    offCanvas();

    teamModal();

    tabs();

    modals();
    counter();
});

window.addEventListener('resize', () => {
    findContainerLeftOffset();
});




// const setFooterHeight = () => {
//     if (window.innerWidth > 1199) {
//         setTimeout(() => {
//             let footerHeight = document.querySelector('footer').offsetHeight;
//             document.querySelector('.sticky-footer').style.height = `${footerHeight}px`;
//         }, 500);
//     }
// }

// const navDropDown = () => {
//     const navItems = document.querySelectorAll('.dropdown');

//     navItems.forEach(item => {
//         let children = item.querySelector('.dropdown-menu');
//         // hover remove hidden class
//         item.addEventListener('mouseover', () => {
//             children.classList.remove('hidden');
//         });
//         item.addEventListener('mouseout', () => {
//             children.classList.add('hidden');
//         });

//     });
// }

// navDropDown();
/**
 * Lightbox Component
 * 
 * Implements a responsive lightbox for an image gallery using Swiper.js.
 *
 * Attributes:
 * - data-gallery: Parent container for gallery images.
 * - data-lightbox: Applied to images that trigger the lightbox.
 * - data-lightbox-container: The container for the lightbox modal..
 * - data-lightbox-pagination: The pagination container for Swiper.js.
 * - data-lightbox-close: The button to close the lightbox.
 *
 * Behavior:
 * - Clicking on an image with `data-lightbox` opens the lightbox.
 * - The lightbox supports navigation using Swiper's next/prev buttons and pagination.
 * - The close button hides the lightbox modal.
 *
 * Example Usage:
 * new Lightbox('[data-gallery]');
 */

import { Navigation, Pagination } from 'swiper/modules';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

class Lightbox {
	constructor(gallerySelector) {
		this.gallery = document.querySelector(gallerySelector);
		if (!this.gallery) {
			console.error('Lightbox: Gallery element not found:', gallerySelector);
			return;
		}

		this.images = this.gallery.querySelectorAll('[data-lightbox-image]');
		this.lightboxContainer = this.gallery.querySelector('[data-lightbox-container]');
		this.closeButton = this.gallery.querySelector('[data-lightbox-close]');
		this.swiper = null;

		this.init();
	}

	init() {
		if (!this.images.length || !this.lightboxContainer) return;

		this.initSwiper();
		this.attachImageEvents();
		this.attachCloseEvent();
		this.attachEscapeKeyEvent();
	}

	initSwiper() {
		const swiperContainer = this.gallery.querySelector('.swiper-container');
		if (!swiperContainer) {
			console.error('Lightbox Gallery: Swiper container not found.');
			return;
		}

		this.swiper = new Swiper(swiperContainer, {
			modules: [Navigation, Pagination],
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: this.gallery.querySelector('[data-lightbox-pagination]'),
				type: 'bullets',
			},
			slidesPerView: 1,
			speed: 400,
			spaceBetween: 16,
			watchOverflow: true,
			zoom: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true,
			},
			mousewheel: {
				sensitivity: 1,
				forceToAxis: true,
				invert: true,
			},
		});
	}

	attachImageEvents() {
		this.images.forEach((image, index) => {
			image.addEventListener('click', (event) => {
				event.preventDefault();
				this.openLightbox(index);
			});
		});
	}

	openLightbox(index) {
		this.lightboxContainer.classList.remove('hidden');
		document.body.classList.add('overflow-hidden');
		this.swiper.slideTo(index, 0, false);
	}

	attachCloseEvent() {
		if (this.closeButton) {
			this.closeButton.addEventListener('click', () => {
				this.closeLightbox();
			});
		}

		// Close lightbox on clicking outside the image
		this.lightboxContainer.addEventListener('click', (event) => {
			if (event.target === this.lightboxContainer) {
				this.closeLightbox();
			}
		});
	}

	attachEscapeKeyEvent() {
		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.closeLightbox();
			}
		});
	}

	closeLightbox() {
		if (this.swiper) {
			// Reset to the first slide before closing
			this.swiper.slideTo(0, 250, false); 
		}
		this.lightboxContainer.classList.add('hidden');
		document.body.classList.remove('overflow-hidden');
	}
}

export default Lightbox;

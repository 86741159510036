const easeOutQuad = t => t * (2 - t); // Easing function for smooth effect

// Function to get the number of decimal places in a number
const getDecimalPlaces = num => {
  const str = num.toString();
  const match = str.match(/\.(\d+)/);
  return match ? match[1].length : 0;
};

const animateCountUp = (el, duration = 400) => {
  const target = parseFloat(el.dataset.count);
  
  const decimalPlaces = getDecimalPlaces(target); // Get decimal places count
  let startTime = null;

  const updateNumber = (timestamp) => {
    if (!startTime) startTime = timestamp;
    const elapsed = timestamp - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const easedProgress = easeOutQuad(progress); // Apply easing
    const currentValue = easedProgress * target;

    // Format based on decimal places
    el.textContent = currentValue.toFixed(decimalPlaces);

    if (progress < 1) requestAnimationFrame(updateNumber);
    else el.textContent = target.toFixed(decimalPlaces); // Ensure exact final number
  };

  requestAnimationFrame(updateNumber);
};

const initCounter = (options = { threshold: 0.5, duration: 1400 }) => {
  const handleIntersection = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        animateCountUp(entry.target, options.duration);
        observer.unobserve(entry.target); // Run only once
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersection, { threshold: options.threshold });
  let counterCMS = document.querySelectorAll(".counter");
  counterCMS.forEach(item => {
    // item's em or i has a number, add data-count attribute to it
    if (item.querySelector("em") || item.querySelector("i")) {
        // check if textContent is a number
        if (!isNaN(item.querySelector("em, i").textContent)) {
            item.querySelector("em, i").setAttribute("data-count", item.querySelector("em, i").textContent);
        }
    }
  });

  document.querySelectorAll("[data-count]").forEach(el => observer.observe(el));



};

export default initCounter;

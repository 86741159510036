/**
 * Accordion Component
 * 
 * Creates an accessible accordion with expandable/collapsible sections.
 *
 * Attributes:
 * - data-accordion: Parent container for the accordion items.
 * - data-accordion-toggle: Button to toggle an accordion item's open/close state.
 * - data-accordion-arrow: Arrow element to visually indicate state.
 * - data-accordion-body: Content container that expands/collapses.
 *
 * Behavior:
 * - Clicking a toggle button expands its corresponding content and collapses others.
 * - Dynamically adjusts the max-height of content for smooth transitions.
 * - Updates aria-expanded for accessibility.
 *
 * Example Usage:
 * new Accordion('[data-accordion]');
 */

export default class Accordion {
	constructor(containerSelector) {
		this.container = document.querySelector(containerSelector);
		if (!this.container) return;

		this.toggles = this.container.querySelectorAll('[data-accordion-toggle]');
		this.init();
	}

	init() {
		this.toggles.forEach((toggle) => {
			toggle.addEventListener('click', () => this.toggleAccordion(toggle));
		});
	}

	toggleAccordion(toggle) {
		const isExpanded = toggle.getAttribute('aria-expanded') === 'true';
		const body = document.querySelector(`#${toggle.getAttribute('aria-controls')}`);
		const arrow = toggle.querySelector('[data-accordion-arrow]');

		// Close all accordion items
		this.toggles.forEach((otherToggle) => {
			const otherBody = document.querySelector(`#${otherToggle.getAttribute('aria-controls')}`);
			const otherArrow = otherToggle.querySelector('[data-accordion-arrow]');

			otherToggle.setAttribute('aria-expanded', 'false');
			otherArrow.style.transform = 'rotate(0deg)';
			otherBody.style.maxHeight = '0';
		});

		// Open the current accordion item if it wasn't already expanded
		if (!isExpanded) {
			toggle.setAttribute('aria-expanded', 'true');
			arrow.style.transform = 'rotate(180deg)';
			body.style.maxHeight = `${body.scrollHeight}px`;
		}
	}
}

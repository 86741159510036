const modals = () => {
    const modalTriggers = document.querySelectorAll('[data-modal-trigger]');

    const modals = document.querySelectorAll('[data-modal]');

    modals.forEach(modal => {
        modal.classList.add('hidden');
    })


    modalTriggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            // lock scroll
            document.body.style.overflow = 'hidden';

            const modalId = trigger.getAttribute('data-modal-trigger');
            const modal = document.getElementById(modalId);

            modals.forEach(modal => {
                modal.classList.add('hidden');
            })
            if (modal) {
                let closer = modal.querySelectorAll('[data-modal-close]')
                closer.forEach(item => {
                    item.addEventListener('click', () => {
                        modal.classList.add('hidden');
                        // unlock scroll
                        document.body.style.overflow = 'auto';
                    });
                });
                modal.classList.remove('hidden');
            }
        });
    });
}

export default modals
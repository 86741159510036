/**
 * Tabs Component
 * 
 * Manages tabbed navigation with data attributes.
 * 
 * Attributes:
 * - `data-tabs`: Parent container for tabs and panels.
 * - `role="tablist"`: Container for tab buttons.
 * - `role="tab"`: Individual tab buttons.
 * - `role="tabpanel"`: Content panels linked to tabs.
 * 
 * Behavior:
 * - Clicking a tab activates its panel and deactivates others.
 * - Updates `aria-selected` on tabs and visibility of panels (`block`/`hidden`).
 * 
 * Constructor:
 * - new Tabs(containerSelector)
 *   - containerSelector: Selector for the `data-tabs` container (string).
 * 
 * Usage:
 * HTML:
 * <div data-tabs>
 *   <nav>
 *     <ul role="tablist">
 *       <li><button role="tab" aria-controls="panel-1" aria-selected="true">Tab 1</button></li>
 *       <li><button role="tab" aria-controls="panel-2" aria-selected="false">Tab 2</button></li>
 *     </ul>
 *   </nav>
 *   <div id="panel-1" role="tabpanel" class="block">Content 1</div>
 *   <div id="panel-2" role="tabpanel" class="hidden">Content 2</div>
 * </div>
 * 
 * JS:
 * new Tabs('[data-tabs]');
 */

export default class Tabs {
	constructor(containerSelector) {
		this.container = document.querySelector(containerSelector);
		if (!this.container) {
			return;
		}

		this.tabs = this.container.querySelectorAll('[role="tab"]');
		this.tabPanels = this.container.querySelectorAll('[role="tabpanel"]');

		this.init();
	}

	init() {
		this.tabs.forEach((tab) => {
			tab.addEventListener('click', (event) => {
				event.preventDefault();
				this.deactivateTabs();
				this.activateTab(tab);
			});
		});
	}

	// Deactivate all tabs and hide all panels
	deactivateTabs() {
		this.tabs.forEach((tab) => {
			tab.classList.remove('tab--active');
			tab.setAttribute('aria-selected', 'false');
		});

		this.tabPanels.forEach((panel) => {
			panel.classList.add('hidden');
		});
	}

	// Activate the clicked tab and display its corresponding panel
	activateTab(tab) {
		tab.classList.add('tab--active');
		tab.setAttribute('aria-selected', 'true');

		const tabPanelId = tab.getAttribute('aria-controls');
		const tabPanel = document.getElementById(tabPanelId);
		if (tabPanel) {
			tabPanel.classList.remove('hidden');
		}
	}
}